import "./App.css";
// import { useEffect } from "react";

import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Home from "./components/Home/Home";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );
}

export default App;
