import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import ContactModal from "../ContactModal/ContactModal";

const tiers = [
  {
    name: "Internet",
    id: "single-play",
    href: "#",
    priceMonthly: "$30",
    description: "Fast and reliable Internet",
    features: [
      "Free Mobile Line",
      "Speeds up to 1 Gig",
      "Free installation",
      "Next day equipment shipping",
    ],
  },
  {
    name: "Internet + Cable",
    id: "double-play",
    href: "#",
    priceMonthly: "$60",
    description: "Enjoy the future of cable",
    features: [
      "85+ popular channels",
      "Free Xumo stream box",
      "Free installation",
      "Next day equipment shipping",
    ],
  },
  {
    name: "Internet + Cable + Mobile",
    id: "triple-play",
    href: "#",
    priceMonthly: "$90",
    description: "The best bundle deal",
    features: [
      "Unlimited talk and text",
      "Fast 5G connection",
      "Free Upgrade to latest phones like iPhone 16",
      "Next day equipment shipping",
    ],
  },
];

export default function PricingTable() {
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);

  function openContactModal() {
    setShowContactModal(true);
  }
  return (
    <>
      <div className="isolate overflow-hidden bg-gray-900">
        <div className="mx-auto max-w-7xl px-6 pb-96 pt-8 text-center lg:px-8">
          <div className="mx-auto max-w-4xl">
            <h2 className="text-base font-semibold leading-7 text-blue-400">
              Limited Time Offer
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              High speed internet and free mobile phone line starting at
              $30/month{" "}
            </p>
          </div>
          <div className="relative mt-6">
            <p className="mx-auto max-w-2xl text-lg leading-8 text-white/60">
              Lower your internet bill by connecting directly with a Spectrum
              sales specialist. Full refund before 30 days if you don't like
              service{" "}
            </p>
            <svg
              viewBox="0 0 1208 1024"
              className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
            >
              <ellipse
                cx={604}
                cy={512}
                rx={604}
                ry={512}
                fill="url(#deep-blue-gradient)"
              />
              <defs>
                <radialGradient id="deep-blue-gradient">
                  <stop stopColor="#0000FF" />
                  <stop offset={1} stopColor="#000080" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="flow-root bg-white pb-24 sm:pb-32">
          <div className="-mt-80">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-3">
                {tiers.map((tier) => (
                  <div
                    key={tier.id}
                    className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                  >
                    <div>
                      <h3
                        id={tier.id}
                        className="text-base font-semibold leading-7 text-blue-600"
                      >
                        {tier.name}
                      </h3>

                      <div className="mt-4 flex items-baseline gap-x-2">
                        <span className="text-5xl font-bold tracking-tight text-gray-900">
                          {tier.priceMonthly}
                        </span>
                        <span className="text-base font-semibold leading-7 text-gray-600">
                          /month
                        </span>
                      </div>
                      <p className="mt-6 text-base leading-7 text-gray-600">
                        {tier.description}
                      </p>
                      <ul
                        role="list"
                        className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                      >
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex gap-x-3">
                            <CheckIcon
                              className="h-6 w-5 flex-none text-blue-600"
                              aria-hidden="true"
                            />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <button
                      onClick={() => {
                        setSelectedTier(tier.id);
                        openContactModal();
                      }}
                      aria-describedby={tier.id}
                      className="mt-8 block rounded-md bg-blue-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      Get started
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showContactModal && (
        <ContactModal
          tier={selectedTier}
          onClose={() => setShowContactModal(false)}
        />
      )}
    </>
  );
}
