import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    customer: {},
  },
  reducers: {
    createOrder: (state, action) => {
      // Mutations are possible within this library because of immer implementation
      state.customer = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { createOrder } = orderSlice.actions;

export default orderSlice.reducer;
